<template>
  <div class="container p-6">
    <div class="columns padding">
      <section class="column">
        <h1 class="h1">
          {{ translations.header.title }}
        </h1>
        <p class="highlight" style="max-width: 400px">
          {{ translations.header.signUp }}
        </p>
        <div class="field has-addons pb-3">
          <div class="control">
            <input
              class="email-input"
              type="text"
              :placeholder="translations.header.emailAddress"
            />
          </div>
          <div class="control control">
            <a class="button is-warning">
              {{ translations.header.submit }}
            </a>
          </div>
        </div>
        <p v-html="translations.header.privacyPolicy"></p>
      </section>
      <div class="column">
        <img class="neni" src="neni.png" alt="" />
      </div>
    </div>
    <img src="brandek.png" class="mobile-mt-reverse-s" alt="" />

    <div class="columns pt-xxl padding">
      <div class="column desktop-pl-6">
        <h2 class="h2">
          {{ translations.monetize.monetizeYourBody }}
        </h2>
        <h2 class="background">
          {{ translations.monetize.monetizeYourBody }}
        </h2>
        <p>
          {{ translations.monetize.p1 }}
        </p>
        <img src="/arrow.svg" class="pointer mobile-invisible" />
        <p class="highlight">
          <b>
            {{ translations.monetize.lowTransaction }}
          </b>
        </p>
        <p>
          {{ translations.monetize.p2 }}
        </p>
      </div>
      <div class="column mobile-ml-reverse-m">
        <img src="screen.png" alt="" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="mycard">
          <img src="/lowfees.png" alt="" />
          <div>
            <h4 class="h4">
              {{ translations.coreValues.lowFees }}
            </h4>
            <p>
              {{ translations.coreValues.lowFeesDesc }}
            </p>
          </div>
        </div>

        <div class="mycard">
          <img src="/newcontent.png" alt="" />
          <div>
            <h4 class="h4">
              {{ translations.coreValues.newContenctTypes }}
            </h4>
            <p>
              {{ translations.coreValues.newContenctTypesDesc }}
            </p>
          </div>
        </div>
      </div>

      <div class="column mt-desktop-6">
        <div id="ui-card" class="mycard">
          <img src="/fancyui.png" alt="" />
          <div>
            <h4 class="h4">
              {{ translations.coreValues.fancyUi }}
            </h4>
            <p>
              {{ translations.coreValues.fancyUiDesc }}
            </p>
          </div>
        </div>

        <div class="mycard">
          <img src="/market.png" alt="" />
          <div>
            <h4 class="h4">
              {{ translations.coreValues.billionMarket }}
            </h4>
            <p>
              {{ translations.coreValues.billionMarketDesc }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h1 class="h0 text-center pt-xl">
        {{ translations.nudeCoin.introducing }}
      </h1>
      <img src="/arrow.svg" class="pointer mobile-invisible" style="margin-left: calc(50% - 20rem);margin-top: 12px;" />
      <h2 class="h2 text-center">
        {{ translations.nudeCoin.nudeCoin }}
      </h2>
      <div class="columns pt-6">

      <div class="column">
        <p class="highlight ">
          {{ translations.nudeCoin.paynudeCurrency }}
        </p>
        <p class="pt-4">
          {{ translations.nudeCoin.userAccess }}
        </p>
      </div>
      <div class="column">
        <img src="/neni2.png" class="neni2" alt="" />
      </div>
      </div>
    </div>

    <div class="staking-benefits">
      <h3 class="h3">
        {{ translations.stakingBenefits.title }}
      </h3>
      <p v-html="translations.stakingBenefits.desc"></p>
    </div>

    <div class="columns text-center pt-l">
      <div class="column">
        <div class="coin-pictogram">
          <img src="/supplydemand.png" alt="" />
        </div>
        <h4 class="h4">
          {{ translations.coinValues.supplyDemand }}
        </h4>
        <p>
          {{ translations.coinValues.supplyDemandDesc }}
        </p>
      </div>
      <div class="column">
        <div class="coin-pictogram">
          <img src="/durability.png" alt="" />
        </div>
        <h4 class="h4">
          {{ translations.coinValues.durability }}
        </h4>
        <p>
          {{ translations.coinValues.durabilityDesc }}
        </p>
      </div>
      <div class="column">
        <div class="coin-pictogram">
          <img src="/reliable.png" alt="" />
        </div>
        <h4 class="h4">
          {{ translations.coinValues.reliable }}
        </h4>
        <p>
          {{ translations.coinValues.reliableDesc }}
        </p>
      </div>
      <div class="column">
        <div class="coin-pictogram">
          <img src="/incorporated.png" alt="" />
        </div>
        <h4 class="h4">
          {{ translations.coinValues.incorporated }}
        </h4>
        <p>
          {{ translations.coinValues.incorporatedDesc }}
        </p>
      </div>
    </div>

    <div class="columns custom-columns pt-l">
      <div class="column">
        <PieChartComponent />

        <h2 class="h2 text-center pt-6"> {{ translations.coinDistribution.million }} </h2>
        <p class="text-center"> {{ translations.coinDistribution.total }} </p>
      </div>
      <div class="column is-two-thirds">
        <h2 class="h2">
          {{ translations.coinDistribution.title }}
        </h2>
        <h2 id="coin-background" class="background">
          {{ translations.coinDistribution.title }}
        </h2>
        <p>
          {{ translations.coinDistribution.subtitle }}
        </p>

        <div class="coin-distribution">
          <div>
            <div>
              <span>15%</span>
              <h4 class="h4">{{ translations.coinDistribution.presale }}</h4>
            </div>
            <p>
              {{ translations.coinDistribution.presaleDesc }}
            </p>
          </div>
          <div>
            <div>
              <span>20%</span>
              <h4 class="h4">{{ translations.coinDistribution.ico }}</h4>
            </div>
            <p>
              {{ translations.coinDistribution.icoDesc }}
            </p>
          </div>
          <div>
            <div>
              <span>5%</span>
              <h4 class="h4">
                {{ translations.coinDistribution.development }}
              </h4>
            </div>
            <p>
              {{ translations.coinDistribution.developmentDesc }}
            </p>
          </div>
          <div>
            <div>
              <span>20%</span>
              <h4 class="h4">{{ translations.coinDistribution.reserve }}</h4>
            </div>
            <p>
              {{ translations.coinDistribution.reserveDesc }}
            </p>
          </div>
          <div>
            <div>
              <span>25%</span>
              <h4 class="h4">{{ translations.coinDistribution.marketing }}</h4>
            </div>
            <p>
              {{ translations.coinDistribution.marketingDesc }}
            </p>
          </div>
          <div>
            <div>
              <span>15%</span>
              <h4 class="h4">{{ translations.coinDistribution.team }}</h4>
            </div>
            <p>
              {{ translations.coinDistribution.teamDesc }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="packages pt-6">
      <div>
        <div>
          <img src="/package1.png" alt="" />
        </div>
        <h3 class="h3 green">$0.05</h3>
        <h4 class="h4">
          {{ translations.packages.presale1 }}
        </h4>
        <ul>
          <li>{{ translations.packages.presale1Duration }}</li>
          <li>{{ translations.packages.presale1Limit }}</li>
          <li>{{ translations.packages.presale1Tokens }}</li>
          <li>{{ translations.packages.presale1Total }}</li>
        </ul>
        <span> {{ translations.packages.comingSoon }}</span>
      </div>
      <div>
        <div>
          <img src="/package2.png" alt="" />
        </div>
        <h3 class="green">$0.05</h3>
        <h4 class="h4">
          {{ translations.packages.presale2 }}
        </h4>
        <ul>
          <li>{{ translations.packages.presale2Duration }}</li>
          <li>{{ translations.packages.presale2Limit }}</li>
          <li>{{ translations.packages.presale2Tokens }}</li>
          <li>{{ translations.packages.presale2Total }}</li>
        </ul>
        <span> {{ translations.packages.comingSoon }}</span>
      </div>
      <div>
        <div>
          <img src="/package3.png" alt="" />
        </div>
        <h3 class="green">$0.05</h3>
        <h4 class="h4">
          {{ translations.packages.publicSale }}
        </h4>
        <ul>
          <li>{{ translations.packages.publicSaleDuration }}</li>
          <li>{{ translations.packages.publicSaleLimit }}</li>
          <li>{{ translations.packages.publicSaleTokens }}</li>
          <li>{{ translations.packages.publicSaleTotal }}</li>
        </ul>
        <span> {{ translations.packages.comingSoon }}</span>
      </div>
    </div>

    <div class="columns pt-xl">
      <div class="column">
        <h2 class="h2">{{ translations.faqs.title }}</h2>
        <h2 id="faq-background" class="background">{{ translations.faqs.title }}</h2>
        <p>
          {{ translations.faqs.subtitle }}
        </p>
      </div>
      <div class="column"></div>
    </div>

    <div class="faq">
      <div>
        <h4 class="h4">
          {{ translations.faqs.q1 }}
        </h4>
        {{ translations.faqs.a1 }}
      </div>
      <div>
        <h4 class="h4">
          {{ translations.faqs.q2 }}
        </h4>
        {{ translations.faqs.a2 }}
      </div>
      <div>
        <h4 class="h4">
          {{ translations.faqs.q3 }}
        </h4>
        {{ translations.faqs.a3 }}
      </div>
      <div>
        <h4 class="h4">
          {{ translations.faqs.q4 }}
        </h4>
        {{ translations.faqs.a4 }}
      </div>
      <div>
        <h4 class="h4">
          {{ translations.faqs.q5 }}
        </h4>
        {{ translations.faqs.a5 }}
      </div>
      <div>
        <h4 class="h4">
          {{ translations.faqs.q6 }}
        </h4>
        {{ translations.faqs.a6 }}
      </div>
      <div>
        <h4 class="h4">
          {{ translations.faqs.q7 }}
        </h4>
        {{ translations.faqs.a7 }}
      </div>
      <div>
        <h4 class="h4">
          {{ translations.faqs.q8 }}
        </h4>
        {{ translations.faqs.a8 }}
      </div>
    </div>

    <div>
      <h2 class="h2 pt-xl pb-6" style="max-width: max(70%, 900px)">
        <img src="/arrow.svg" class="pointer mobile-invisible" style="margin-left: -5.5rem;margin-top: 12px;" />
        {{ translations.header.signUp }}
      </h2>
      <h2 id="signup-background" class="background" style="margin-top: -15rem;margin-left: 45%;">
        {{ translations.header.signUp }}
      </h2>
      <div class="field has-addons pb-3">
        <div class="control">
          <input
            class="email-input"
            type="text"
            :placeholder="translations.header.emailAddress"
          />
        </div>
        <div class="control control">
          <a class="button is-warning">
            {{ translations.header.submit }}
          </a>
        </div>
      </div>
      <p
        class="pb-6"
        style="max-width: max(40%, 500px)"
        v-html="translations.header.privacyPolicy"
      ></p>
    </div>
  </div>
  <div class="" style="background: black">
    <div class="container p-6">
      <div class="columns">
        <div class="column is-three-quarters">

          <div class="columns">
            <div class="column">
              <img src="/logo.png" class="pb-5" />
              <p class="pb-3">
              © 2022 PayNude Inc. 
              </p>
              <p>
                {{ translations.footer.allRights }}
              </p>
            </div>
            <div class="column">
              <h4 class="h4 pb-5">
                {{ translations.footer.company }}
              </h4>
              <p class="pb-3">
                {{ translations.footer.aboutUs }}
              </p>
              <p>
                {{ translations.footer.contactUs }}
              </p>
            </div>
            <div class="column">
              <h4 class="h4 pb-5">
                {{ translations.footer.contact }}
              </h4>
              <p class="pb-3">
                +36 1 954 2386
              </p>
              <p>
                hello@paynude.com
              </p>
            </div>
            <div class="column">
              <h4 class="h4 pb-5">
                {{ translations.footer.legal }}
              </h4>
              <p class="pb-3">
                {{ translations.footer.privacyPolicy }}
              </p>
              <p>
                {{ translations.footer.termsOfUse }}
              </p>
            </div>
          </div>

      <div>
        <h5 class="h5 pt-6">
          {{ translations.footer.legalDisclaimer }}
        </h5>
        <p class="note">
          {{ translations.footer.legalDisclaimerText }}
        </p>
      </div>
      <div>
        <h5 class="h5">
          {{ translations.footer.riskDisclosure }}
        </h5>
        <p class="note">
          {{ translations.footer.riskDisclosureText }}
        </p>
      </div>
        </div>
        <div class="column social">
          <i class="fa-brands fa-facebook-square"></i>
          <i class="fa-brands fa-instagram"></i>
          <i class="fa-brands fa-youtube"></i>
          <i class="fa-brands fa-linkedin-in"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PieChartComponent from './chart-component/PieChartComponent.vue';

export default {
  name: "HomeComponent",
  components: { PieChartComponent },
  computed: {
    ...mapGetters(["translations"]),
  },
};
</script>

<style lang="scss" scoped>
.neni {
  position: absolute;
  max-height: 1457px;
  margin-left: -200px;
  top: -150px;
  z-index: -5;
}

.neni2 {
  position: absolute;
  right: -48px;
  margin-top: -10rem;
  z-index: -1;
  opacity: 0.9;
  filter: drop-shadow(-50px -46px 74px rgba(0, 0, 0, 0.3));
  max-width: max(60%, 600px);
}

.social {
  i {
    margin: 0 1rem;
  }
}
</style>
