import { createStore } from 'vuex';
import strings from '../strings.json';

export default createStore({
  state: {
    translations: strings
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  getters: {
    translations: (state) => state.translations
  }
});
