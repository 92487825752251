<template>
  <div id="chart">
    <div 
      v-for="segment in segments"
      :key="segment.id"
      :id="'segment' + segment.id"
      class="circle animate segment"
      :data-label="segment.label"
      :style="style(segment)"
    ></div>
  </div>
</template>

<script> 
const { calculateAngles, calculateCoordinates, degToPercent } = require('./pie-chart-calculator');

export default {
  name: "PieChartComponent",
  data() {
    return {
      segments: [
        {
          label: 'Pre-sale',
          value: 15,
          textDir: 'ltr'
        },
        {
          label: 'ICO',
          value: 20,
          textDir: 'rtl'
        },
        {
          label: 'Development',
          value: 5,
          textDir: 'rtl'
        },
        {
          label: 'Reserve',
          value: 20,
          textDir: 'rtl'
        },
        {
          label: 'Marketing',
          value: 25,
          textDir: 'ltr'
        },
        {
          label: 'Team',
          value: 15,
          textDir: 'ltr'
        },
      ]
    }
  },
  methods: {
    style(segment) {
      return `
        --coordinates: ${degToPercent(segment.coordinates)};
        --background-deg: ${segment.labelDeg}deg;
        --label-deg: ${this.mirroredText(segment.textDir) ? segment.labelDeg - 180 : segment.labelDeg}deg;
        --label-margin: ${this.mirroredText(segment.textDir) ? 50 : 5}%;
        --label-transform-origin: ${this.mirroredText(segment.textDir) ? 0 : 100}% 50%;
        --label-justify-content: ${this.mirroredText(segment.textDir) ? 'flex-end' : 'flex-start'};
      `;
    },
    mirroredText(dir) {
      return dir === 'rtl';
    }
  },
  mounted() {
    this.segments = this.segments.map((x, i) => ({ id: i, ...x }))
    this.segments = calculateAngles(this.segments)
    this.segments = calculateCoordinates(this.segments)
  }
};

</script>

<style lang="scss" scoped>

#chart {
  position: relative;
  width: 400px;
  height: 400px;
  max-width: min(400px, 80vw);
  max-height: min(400px, 80vw);
  margin-left: -0.75rem;
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  background-color: rgb(196, 255, 220);
}

.animate {
  -webkit-transition: 0.2s cubic-bezier(.74,1.13,.83,1.2);
  -moz-transition: 0.2s cubic-bezier(.74,1.13,.83,1.2);
  -o-transition: 0.2s cubic-bezier(.74,1.13,.83,1.2);
  transition: 0.2s cubic-bezier(.74,1.13,.83,1.2);
}

.animate::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(177.57deg, rgba(255, 0, 229, 0.3) 70.4%, rgba(189, 0, 255, 0.5) 118.76%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.4s linear;
  border-radius: 50%;
}

.animate:hover::before {
  opacity: 1;
}

.segment {
  -webkit-clip-path: polygon(var(--coordinates));
  clip-path: polygon(var(--coordinates));

  background: linear-gradient(var(--background-deg), rgba(255, 255, 255, 0.05) 0, rgba(255, 255, 255, 0.4) 100%); 

  &::after {
    content: attr(data-label);
    position: absolute;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    color: white;
    width: 45%;
    margin-top: calc(50% - 0.5rem);

    margin-left: var(--label-margin);
    transform-origin: var(--label-transform-origin);
    transform: rotate(var(--label-deg));
    justify-content: var(--label-justify-content);
  }
}

</style>