<template>
  <div class="navbar is-transparent container p-6" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="#">
        <img src="/logo.png" />
      </a>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        v-bind:class="{ 'is-active': burgerActive }"
        @click="onBurgerClicked()">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu" v-bind:class="{ 'is-active': menuActive }">
      <div class="navbar-end">
        <a class="navbar-item">
          {{ translations.navBar.platform }}
        </a>
        <a class="navbar-item">
          {{ translations.navBar.faqs }}
        </a>
        <a class="navbar-item">
          {{ translations.navBar.contactUs }}
        </a>

        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-warning">
              {{ translations.navBar.whitepaper }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {
      burgerActive: false,
      menuActive: false,
    };
  },
  computed: {
    ...mapGetters(['translations'])
  },
  methods: {
    onBurgerClicked() {
      this.burgerActive = !this.burgerActive;
      this.menuActive = !this.menuActive;
    },
  },
}
</script>

<style lang="scss">
.navbar.is-transparent {
  background: transparent;
}

.navbar-brand .navbar-item,
.navbar-end .navbar-item {
  color: #ffffff;
}

</style>