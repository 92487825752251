<template>
  <nav-bar />
  <Home />
</template>

<script>
import Home from './components/Home.vue'
import NavBar from './components/NavBar.vue'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'App',
  components: {
    Home, NavBar
  }
}
</script>

<style lang="scss">
#app {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 130%;
  font-family: Poppins, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}
</style>
