const { assert } = require("chai")

const calculateAngles = (values) => {
  assert(Array.isArray(values) && values.length > 0, 'input should be not empty array')

  const sum = values.reduce((prev, x) => prev += x.value, 0);
  const degPerValue = 360/sum;

  return values.map(x => ({...x, deg: degPerValue * x.value }));
}

const calculateCoordinateForValue = (value, startValue, startCoordinates) => {
  const coordinates = [];
  if (!startCoordinates) {
    coordinates.push([0, 0]);
  } else {
    coordinates.push(startCoordinates)
  }

  const degToCalc = startValue + value.deg;
  if (degToCalc <= 90) {
    const x = Math.sqrt(2) * (Math.sin(toRadians(degToCalc)) / Math.sin(toRadians(135 - degToCalc))) * 50;
    coordinates.push([Number(x.toFixed(2)), 0])
  }
  
  if (degToCalc > 90 && degToCalc <= 180) {
    const y = Math.sqrt(2) * (Math.sin(toRadians(degToCalc - 90)) / Math.sin(toRadians(135 - degToCalc + 90))) * 50;
    startValue < 90 && coordinates.push([100, 0])
    coordinates.push([100, Number(y.toFixed(2))])
  }
  
  if (degToCalc > 180 && degToCalc <= 270) {
    startValue < 90 && coordinates.push([100, 0])
    startValue < 180 && coordinates.push([100, 100])
    const x = Math.abs(100 - Math.sqrt(2) * (Math.sin(toRadians(degToCalc - 180)) / Math.sin(toRadians(135 - degToCalc + 180))) * 50);
    coordinates.push([Number(x.toFixed(2)), 100])
  }

  if (degToCalc > 270 && degToCalc <= 360) {
    startValue < 90 && coordinates.push([100, 0])
    startValue < 180 && coordinates.push([100, 100])
    startValue < 270 && coordinates.push([0, 100])
    const y = Math.abs(100 - Math.sqrt(2) * (Math.sin(toRadians(degToCalc - 270)) / Math.sin(toRadians(135 - degToCalc + 270))) * 50);
    coordinates.push([0, Number(y.toFixed(2))])
  }

  return coordinates
}

const calculateCoordinates = (values) => {
  assert(Array.isArray(values) && values.length > 0, 'input should be not empty array')

  if (values.length === 1) {
    values[0].coordinates = [[0, 0], [100, 0], [100, 100], [0, 100]]
    return values
  }

  const coordinates = [];

  let startCoordinates
  let startValue = 0
  for (let value of values) {
    const res = calculateCoordinateForValue(value, startValue, startCoordinates)

    value.labelDeg = 45 + (startValue + value.deg/2)

    startValue += value.deg;
    startCoordinates = res[res.length - 1];
    res.push([50, 50])
    value.coordinates = res
    coordinates.push(res)
  }
  return values
}

const degToPercent = (coordinates) => {
  if (!coordinates) {
    return;
  }
  return coordinates.flatMap(x => x.map(x => `${x}%`).join(' ')).join(', ');
}

module.exports = {
  calculateAngles, calculateCoordinates, calculateCoordinateForValue, degToPercent
}

function toRadians (angle) {
  return angle * (Math.PI / 180);
}
